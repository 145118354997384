<template>
  <div>
    <Edit
      :editDialog="editDialog"
      @close="editDialog = false"
      @submit="getLead"
      :editLead="selectedLead"
    ></Edit>
    <v-alert
      v-if="lead"
      border="left"
      type="error"
      tile
      transition="slide-y-transition"
      dismissible
      class="mb-2"
      v-model="lead.alert"
      color="red"
    >
      <strong>{{ lead.note }}</strong>
    </v-alert>
    <v-card tile flat v-if="lead">
      <v-toolbar dense flat color="grey lighten-3">
        <v-app-bar-nav-icon @click="$router.go(-1)">
          <v-icon>mdi-chevron-left</v-icon>
        </v-app-bar-nav-icon>

        <v-toolbar-title class="text-capitalize">
          {{ lead.name }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-menu bottom left offset-y>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon>
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>
          <v-list class="menu-button">
            <v-list-item v-if="checkRightStatus(44)" @click="editLead(lead)">
              <v-list-item-title>Edit</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="checkRightStatus(45)" @click="deleteLead(lead)">
              <v-list-item-title>Delete</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>

      <v-card-text>
        <v-row
          class="mt-5"
          v-if="!lead"
          align-center
          justify-center
          column
          fill-height
        >
          <v-progress-circular
            size="70"
            width="7"
            color="purple"
            indeterminate="indeterminate"
          ></v-progress-circular>
          <div>Loading...</div>
        </v-row>
        <v-row row wrap v-else justify-space-between>
          <v-col shrink>
            <div class="text-capitalize">
              <span class="font-weight-medium">Contact Name: </span>
              <span v-if="lead.contactName">{{ lead.contactName }}</span>
              <span v-else>Not Set</span>
            </div>
            <div class="text-capitalize">
              <span class="font-weight-medium">Phone Number: </span>
              <span v-if="lead.phoneNumber">{{
                lead.phoneNumber | USPhoneNumber
              }}</span>
              <span v-else>Not Set</span>
            </div>
            <div>
              <span class="font-weight-medium">Email: </span>
              <a v-if="lead.email" @click="sendEmail(lead.email)">{{
                lead.email
              }}</a>
              <span v-else>Not Set</span>
            </div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col shrink>
            <div class="text-right">
              <v-tooltip bottom v-if="lead.address">
                <template v-slot:activator="{ on }">
                  <a v-on="on" @click="openGoogleMap">
                    <div class="text-capitalize title">
                      {{ lead.address }}
                    </div>
                    <div class="text-capitalize">
                      {{ lead.city }}, {{ lead.state }}
                      {{ lead.zip }}
                    </div>
                  </a>
                </template>
                <span>Click to open google map</span>
              </v-tooltip>

              <div class="mt-1">
                <span class="font-weight-medium">Created At: </span>
                <span>{{ lead.createdAt | moment("MM/DD/YYYY") }}</span>
              </div>
              <div class="mt-1">
                <span class="font-weight-medium">Status: </span>
                <span>
                  <v-chip label color="pink" text-color="white">{{
                    lead.status
                  }}</v-chip>
                </span>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-divider></v-divider>
    <v-container grid-list-lg fluid>
      <v-layout row wrap>
        <v-flex xs12 sm4>
          <v-card outlined>
            <v-toolbar dense flat color="grey lighten-3">
              <v-toolbar-title> Note </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn
                v-if="checkRightStatus(36)"
                small
                color="info"
                dark
                class="mr-2"
                @click="updateNote()"
                fab
                text
              >
                <v-icon>save</v-icon>
              </v-btn>
              <div v-if="checkRightStatus(37)">
                <span class="mr-2">Alert</span>
                <toggle-button
                  @change="updateAlert"
                  :labels="true"
                  v-if="lead"
                  color="red"
                  v-model="lead.alert"
                  :value="lead.alert"
                  :sync="true"
                ></toggle-button>
              </div>
            </v-toolbar>
            <v-card-text>
              <v-textarea
                v-model="note"
                outlined
                hide-details
                label="Lead Note"
              ></v-textarea>

              <!-- <div v-if="lead && lead.note">{{ lead.note }}</div> -->
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs12 sm8 v-if="lead">
          <Comment :RelationId="lead" :TypeId="13" />
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import leadService from "../service";
import Comment from "@/modules/Comment/components/List";
import { mapGetters } from "vuex";
const Edit = () => import("../components/Edit");

export default {
  name: "laed-profile",
  data() {
    return {
      editDialog: false,
      lead: null,
      selectedLead: null,
      note: null,
    };
  },
  created() {
    this.getLead();
  },
  components: {
    Comment,
    Edit,
  },
  computed: {
    ...mapGetters("global", ["currentUser", "checkRightStatus"]),
  },
  methods: {
    updateAlert(val) {
      let data = {
        alert: val.value,
      };
      this.updateLead(data);
    },
    updateNote() {
      let data = {
        note: this.note,
      };
      this.updateLead(data);
    },
    updateLead(data) {
      return leadService.update(this.lead.id, data).then(() => {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          .fire({
            icon: "success",
            title: "Update Successfully",
          });
        this.getLead();
      });
    },
    editLead(lead) {
      this.selectedLead = lead;
      this.editDialog = !this.editDialog;
    },
    deleteLead(lead) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "No",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          leadService.delete(lead.id).then((response) => {
            if (response.data) {
              this.$swal("Deleted!", "Lead has been deleted.", "success");
              this.$router.go(-1);
            }
          });
        }
      });
    },
    getLead() {
      const { id } = this.$route.params;
      return leadService.getById(id).then((response) => {
        // console.log("response", response.data);
        this.lead = response.data;
        this.note = this.lead.note;
      });
    },
  },
};
</script>

<style scoped></style>
