<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500" persistent>
      <template v-slot:activator="{ on }">
        <v-btn color="green lighten-2" dark v-on="on">
          {{ btnLabel }}
          <!-- <span
            v-if=" (currentBusiness && currentBusiness.subscription_id) && (Subscription && Subscription.status !== 'canceled')"
            >Re-Subscription</span
          >
          <span v-else>Change Plan</span> -->
        </v-btn>
      </template>

      <v-card>
        <ModelTitle title="Plans" @close="dialog = false" />

        <v-card flat :loading="isLoading" v-if="plans.length > 0">
          <v-card-text v-if="sources.length < 0">
            <div class="text-center">
              We found no card on file plesae enter card before re-subscribing
            </div>
            <AddCard class="mt-3" />
          </v-card-text>

          <v-card-text v-else>
            <v-radio-group v-model="selectedPlan" column>
              <v-radio
                :value="plan"
                :key="index"
                v-for="(plan, index) in plans"
              >
                <template v-slot:label>
                  <div>
                    <div class="title">{{ plan.nickname }}</div>
                    <div>{{ (plan.amount / 100) | currency }}</div>
                  </div>
                </template>
              </v-radio>
            </v-radio-group>
          </v-card-text>
        </v-card>

        <v-card v-else flat>
          <v-card-text class="text-center">
            THERE IS NO PLANS FOUND
          </v-card-text>
        </v-card>
        <!-- <v-img src="@/assets/images/stripeimg.jpg" /> -->

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="submit"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AddCard from "./AddCard";
import accountService from "../service";
import { mapGetters, mapActions } from "vuex";

export default {
  props: ["sources", "Subscription"],
  data() {
    return {
      selectedPlan: null,
      isLoading: true,
      dialog: false,
      Customer: null,
      Items: null,
      plans: [],
      stripe: null,
    };
  },
  computed: {
    ...mapGetters("global", ["currentBusiness"]),
    btnLabel() {
      let label = "Re-Subscrib";
      if (this.Subscription && this.Subscription.status !== "canceled") {
        label = "Change Plan";
      } else {
        label = "Re-Subscrib";
      }

      return label;
    },
  },
  components: {
    AddCard,
  },
  watch: {
    dialog(val) {
      if (val) {
        this.getCustomer();
        this.getPlans();
        if (this.currentBusiness.subscription_id) {
          this.getSubscriptionItems();
          // this.getSubscription();
        }
      }
    },
    Subscription(val) {
      this.plans.map((plan) => {
        if (val.plan.id === plan.id) {
          this.selectedPlan = plan;
        }
      });
    },
  },
  methods: {
    ...mapActions("global", ["initUser", "initBusiness"]),
    async submit() {
      if (this.Subscription && this.Subscription.status !== "canceled") {
        this.changePlan();
      } else {
        if (this.sources && this.sources.length > 0) {
          this.createSubscription();
        } else {
          await this.AddCard();
          await this.createSubscription();
        }
      }
    },
    createSubscription() {
      this.isLoading = true;
      return accountService
        .createSubscrition({
          customer_id: this.currentBusiness.customer_id,
          plan_id: this.selectedPlan.id,
        })
        .then(async () => {
          await this.initUser();
          // await this.getSubscription();
          this.$emit("planChange");
          this.isLoading = false;
          this.dialog = false;
          this.$router.push("/");
        })
        .catch((error) => {
          console.log("error", error);
          this.isLoading = false;
          let message = error.data.message;
          if (error.data.code === "100000") {
            message = "Please add payment method before selecting plan.";
          }
          this.$swal
            .mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: true,
              timer: 10000,
            })
            .fire({
              icon: "error",
              title: message,
            });
        });
    },
    changePlan() {
      this.$swal({
        title: "Are you sure?",
        text: "You want to change plan",
        icon: ", 'warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "No",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;
          let data = {
            item_id: this.Items[0].id,
            plan_id: this.selectedPlan.id,
          };
          return accountService
            .changePlan(data)
            .then(() => {
              // this.getSubscription();
              this.$emit("planChange");
              this.isLoading = false;
              // console.log("updateCustomer", result);
            })
            .catch((error) => {
              console.log("error", error);
            });
        }
      });
    },
    // getSubscription() {
    //   this.isLoading = true;
    //   return accountService
    //     .getSubscription({
    //       subscription_id: this.currentBusiness.subscription_id,
    //     })
    //     .then((result) => {
    //       this.Subscription = result.data;
    //       this.isLoading = false;
    //       // console.log("Subscription", result);
    //     });
    // },
    getSubscriptionItems() {
      this.isLoading = true;
      return accountService
        .getSubscriptionItems({
          subscription_id: this.currentBusiness.subscription_id,
        })
        .then((result) => {
          this.Items = result.data;
          this.isLoading = false;
          // console.log("Items", result);
        });
    },
    getCustomer() {
      this.isLoading = true;
      return accountService
        .getCustomer({ customer_id: this.currentBusiness.customer_id })
        .then((result) => {
          this.Customer = result.data;
          this.isLoading = false;
          // console.log("result", result);
        });
    },
    getPlans() {
      this.isLoading = true;
      return accountService.getPlans().then((result) => {
        this.plans = result.data;
        this.isLoading = false;
      });
    },
  },
};
</script>
