<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500" persistent>
      <template v-slot:activator="{ on }">
        <v-btn color="green lighten-2" dark v-on="on"> Card On File </v-btn>
      </template>

      <v-card>
        <ModelTitle title="Card On File" @close="dialog = false" />

        <v-card flat :loading="isLoading" v-if="cards.length > 0">
          <v-card-text v-if="currentBusiness">
            <v-list two-line>
              <template v-for="(card, index) in cards">
                <div :key="index">
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-btn small text icon @click="deleteCard(card)">
                        <v-icon small>close</v-icon>
                      </v-btn>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ card.brand }}</v-list-item-title>
                      <v-list-item-subtitle
                        >{{ card.last4 }} - {{ card.exp_month }}/{{
                          card.exp_year
                        }}</v-list-item-subtitle
                      >
                    </v-list-item-content>

                    <v-list-item-action
                      v-if="Customer && Customer.default_source === card.id"
                    >
                      <v-chip
                        small
                        class="ma-2"
                        color="blue lighten-3"
                        text-color="blue darken-3"
                      >
                        Default
                      </v-chip>
                    </v-list-item-action>
                    <v-list-item-action v-else>
                      <v-btn
                        text
                        small
                        :loading="isLoading"
                        color="success"
                        @click="changeDefaultCard(card)"
                        >Use This</v-btn
                      >
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider v-if="index + 1 < cards.length"></v-divider>
                </div>
              </template>
            </v-list>
          </v-card-text>
        </v-card>

        <v-card v-else flat>
          <v-card-text class="text-center"> NO CARD ON FILE </v-card-text>
        </v-card>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close()"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import accountService from "../service";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      isLoading: true,
      dialog: false,
      Customer: null,
      cards: [],
      complete: false,
      stripePublicKey: process.env.VUE_APP_STRIPE_PK,
      stripe: null,
    };
  },
  computed: {
    ...mapGetters("global", ["currentBusiness"]),
  },
  watch: {
    dialog(val) {
      if (val) {
        this.getCustomer();
        this.getCards();
      }
    },
  },
  methods: {
    close() {
      this.dialog = false;
    },
    deleteCard(card) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "No",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;
          let data = {
            customer_id: this.currentBusiness.customer_id,
            card_id: card.id,
          };
          return accountService
            .deleteCard(data)
            .then(() => {
              this.$emit("deleteCard");
              this.getCards();
              this.getCustomer();
              this.isLoading = false;
              // console.log("deleteCard", result);
            })
            .catch((error) => {
              console.log("error", error);
            });
        }
      });
    },
    changeDefaultCard(card) {
      this.isLoading = true;
      let data = {
        customer_id: this.currentBusiness.customer_id,
        default_source: card.id,
      };
      return accountService
        .changeDefaultCard(data)
        .then(() => {
          this.getCustomer();
          this.isLoading = false;
          // console.log("updateCustomer", result);
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    getCustomer() {
      this.isLoading = true;
      return accountService
        .getCustomer({ customer_id: this.currentBusiness.customer_id })
        .then((result) => {
          this.Customer = result.data;
          this.isLoading = false;
          // console.log("result", result);
        });
    },
    getCards() {
      this.isLoading = true;
      return accountService
        .getCards({ customer_id: this.currentBusiness.customer_id })
        .then((result) => {
          this.cards = result.data;
          this.isLoading = false;
          // console.log("result", result);
        });
    },
  },
};
</script>
