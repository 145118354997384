<template>
  <v-container>
    <!-- || Subscription && Subscription.status === 'incomplete_expired' -->
    <div v-if="
      (currentBusiness && !currentBusiness.subscription_id) ||
      (Subscription && Subscription.status === 'incomplete_expired')
    ">
      <div class="text-center">
        <h2 class="title">Welcome</h2>
        <small>Setup subscription</small>
        <v-row class="mt-5">
          <v-col cols="12">
            <h2 class="title mb-2">Enter a payment method</h2>
          </v-col>
          <v-col cols="12" align="center">
            <v-card outlined flat width="500" :loading="isLoading">
              <v-card-text>
                <card ref="card" class="stripe-card mt-7" :class="{ complete }" :stripe="stripePublicKey"
                  @change="complete = $event.complete" />

                <v-img src="@/assets/images/stripeimg.jpg" />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <v-col class="mt-3" align="center">
        <v-row cols="12" justify="center">
          <h2 class="title mb-2">Select Plan</h2>
        </v-row>
        <v-col cols="12">
          <v-card outlined flat width="500" :loading="isLoading">
            <v-card-text>
              <v-radio-group v-model="selectedPlan" column>
                <v-radio :value="plan" :key="index" v-for="(plan, index) in plans">
                  <template v-slot:label>
                    <div>
                      <div class="title" v-if="plan.nickname">{{ plan.nickname }}</div>
                      <div>{{ (plan.amount / 100) | currency }}</div>
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-card-text>
          </v-card>
        </v-col>

        <v-btn v-if="selectedPlan" color="success" class="mt-3" :loading="btnLoading" :disabled="!complete" rounded
          large @click="submit">{{ (selectedPlan.amount / 100) | currency }} Charge</v-btn>
      </v-col>
      <v-col class="mt-3" align="center">
        <v-row cols="12" justify="center">
          <v-btn color="primary" to="/logout">{{
            $t("buttons.logout")
          }}</v-btn>
        </v-row>
      </v-col>
    </div>

    <div v-else>
      <h2 class="title mb-2">Current Plan</h2>
      <v-card outlined :loading="isLoading">
        <v-toolbar class="elevation-0" height="45" color="gray">
          <v-toolbar-title>
            <h1 class="title font-weight-regular">
              smplsale unlimited service
            </h1>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-chip v-if="Subscription" :color="color" small class="text-capitalize">{{ Subscription.status }}</v-chip>
        </v-toolbar>
        <v-card-text v-if="Subscription && Subscription.plan">
          <h3>{{ Subscription.plan.nickname }} Account</h3>
          <h3>
            {{ (Subscription.plan.amount / 100) | currency }} every
            {{ Subscription.plan.interval }}
          </h3>
          <span>Change plan to Quarterly or Yearly to save money</span>
        </v-card-text>
        <div v-if="Subscription && !Subscription.plan">
          <v-card-text v-if="Subscription && Subscription.items" v-for="(item, index) in Subscription.items.data"
            :key="index">
            <h3>{{ item.plan.nickname }} Account</h3>
            <h3>
              {{ (item.plan.amount / 100) | currency }} every
              {{ item.plan.interval }}
            </h3>
            <span>Change plan to Quarterly or Yearly to save money</span>
          </v-card-text>
          <v-card-text v-else class="text-center title">
            You have not selected any plan yet.
          </v-card-text>
        </div>


        <v-divider></v-divider>
        <v-card-actions>
          <v-row>
            <v-col v-if="Subscription && Subscription.status !== 'canceled'">
              <v-btn text @click="cancelSubscrition">Cancel Subscription</v-btn>
            </v-col>
            <v-spacer></v-spacer>
            <!-- <v-col class="shrink">
              
              <ChangePlan
                :sources="sources"
                :Subscription="Subscription"
                @planChange="getSubscription()"
            />
          </v-col> -->
          </v-row>
        </v-card-actions>
      </v-card>

      <h2 class="mt-3 title mb-2">Invoicing</h2>
      <v-card outlined :loading="isLoading">
        <v-card-text v-if="Subscription">
          <!-- <div>
          <span>Last Payment: </span>
          <span>{{
            Subscription.current_period_start | moment("MM/DD/YYYY")
          }}</span>
        </div> -->
          <div v-if="Subscription">
            <span>Next Payment: </span>
            <span class="font-weight-bold">{{
              Subscription.current_period_end | moment("MM/DD/YYYY")
            }}</span>
          </div>
        </v-card-text>
        <v-card-text>
          <InvoiceList />
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-row>
            <v-spacer></v-spacer>
            <v-col class="shrink">
              <AddCard :sources="sources" @submit="getCustomer()" />
            </v-col>
            <v-col class="shrink" v-if="Customer && sources.length > 0">
              <CardOnFile :sources="sources" @deleteCard="getCustomer()" />
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import AddCard from "./AddCard";
import CardOnFile from "./CardOnFile";
import ChangePlan from "./Plans";
import InvoiceList from "./InvoiceList";
import { Card, createToken } from "vue-stripe-elements-plus";
import accountService from "@/modules/Account/service.js";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "account-home",
  data() {
    return {
      sources: [],
      btnLoading: false,
      selectedPlan: null,
      Customer: null,
      Subscription: null,
      isLoading: true,
      cards: [],
      complete: false,
      stripePublicKey: process.env.VUE_APP_STRIPE_PK,
      stripe: null,
      plans: null,
    };
  },
  async created() {
    await this.initUser();
    await this.getCustomer();
    await this.getPlans();
    if (this.currentBusiness.subscription_id) await this.getSubscription();
    await this.setPlaninit();
    this.isLoading = false;
  },
  components: {
    AddCard,
    CardOnFile,
    ChangePlan,
    Card,
    InvoiceList,
  },
  computed: {
    ...mapGetters("global", [
      "currentUser",
      "checkRightStatus",
      "currentBusiness",
    ]),
    color() {
      let color = "gray";
      if (this.Subscription.status === "active") color = "blue lighten-5";
      if (this.Subscription.status === "canceled")
        color = "deep-orange lighten-1";
      if (this.Subscription.status === "past_due") color = "yellow lighten-2";
      if (this.Subscription.status === "unpaid") color = "orange lighten-2";

      return color;
    },
  },
  methods: {
    ...mapActions("global", ["initUser", "initBusiness"]),
    setPlaninit() {
      this.plans.map((plan) => {
        if (plan.nickname === "Quarterly") this.selectedPlan = plan;
      });
    },
    getPlans() {
      this.isLoading = true;
      return accountService.getPlans().then((result) => {
        console.log('result get plan', result)
        this.plans = result.data;
        this.isLoading = false;
      });
    },
    async submit() {
      // console.log("in submit");
      try {
        this.btnLoading = true;
        let customer_id = this.currentBusiness.customer_id;
        if (!this.currentBusiness.customer_id) {
          const customer = await accountService.createCustomer({
            email: this.currentBusiness.email,
            name: this.currentBusiness.name,
            phone: this.currentBusiness.phoneNumber,
            description: `Account Code: ${this.currentBusiness.code}`,
            metadata: {
              accountCode: this.currentBusiness.code,
              phoneNumber: this.currentBusiness.phoneNumber,
            },
          });
          customer_id = customer.id;
        }
        // console.log("customer_id", customer_id);
        const data = await createToken();
        await accountService.addCard({
          card: {
            customer_id: customer_id,
            source: data.token.id,
          },
        });

        await accountService.createSubscrition({
          customer_id: customer_id,
          plan_id: this.selectedPlan.id,
        });

        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          .fire({
            icon: "success",
            title: "Subscrition Start",
          });
        this.btnLoading = false;
        this.$router.push("/");
      } catch (error) {
        // console.log("error adding card", error);
        this.btnLoading = false;
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          .fire({
            icon: "error",
            title: error.message,
          });
      }
    },
    cancelSubscrition() {
      this.$swal({
        title: "Are you sure?",
        text: "You can to cancel you subscription",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "No",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;
          return accountService
            .cancelSubscrition({
              subscription_id: this.currentBusiness.subscription_id,
            })
            .then(() => {
              // console.log("result", result);
              this.initUser();
              this.getSubscription();
              this.isLoading = false;
            });
        }
      });
    },
    getSubscription() {
      return accountService
        .getSubscription({
          subscription_id: this.currentBusiness.subscription_id,
        })
        .then((result) => {
          console.log("asdfksdfsdf", result);
          this.Subscription = result.data;
        });
    },
    getCustomer() {
      return accountService
        .getCustomer({ customer_id: this.currentBusiness.customer_id })
        .then((result) => {
          // console.log("result customer", result);
          this.Customer = result.data;
          this.sources = this.Customer.sources.data;
        });
    },
  },
};
</script>

<style>
/* .stripe-card {
  width: 300px;
  border: 1px solid grey;
}
.stripe-card.complete {
  border-color: green;
} */
</style>
