<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500" persistent>
      <template v-slot:activator="{ on }">
        <v-btn color="red lighten-2 text-right" dark v-on="on">
          Add Card
        </v-btn>
      </template>

      <v-card>
        <ModelTitle title="Add Card" @close="close" />
        <v-card-text v-if="currentBusiness">
          <v-card class="mt-5" flat outlined>
            <v-card-text>
              <card
                ref="card"
                class="stripe-card"
                :class="{ complete }"
                :stripe="stripePublicKey"
                @change="complete = $event.complete"
              />
            </v-card-text>
          </v-card>
          <v-card-text>
            <v-switch
              v-if="sources && sources.length > 0"
              v-model="defaultCard"
              inset
              label="Make is card default for future payments"
            ></v-switch>
          </v-card-text>
        </v-card-text>

        <v-img src="@/assets/images/stripeimg.jpg" />

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :loading="isLoading"
            :disabled="!complete"
            color="primary"
            text
            @click="submit"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Card, createToken } from "vue-stripe-elements-plus";
import accountService from "@/modules/Account/service.js";
import { mapGetters } from "vuex";

export default {
  props: ["sources"],
  data() {
    return {
      defaultCard: false,
      isLoading: false,
      dialog: false,
      cards: [],
      complete: false,
      stripePublicKey: process.env.VUE_APP_STRIPE_PK,
      stripe: null,
    };
  },
  watch: {
    dialog(val) {
      if (val) {
        if (this.sources && this.sources > 0) {
          this.defaultCard = false;
        } else {
          this.defaultCard = true;
        }
      }
    },
  },
  computed: {
    ...mapGetters("global", ["currentBusiness"]),
  },
  components: {
    Card,
  },
  methods: {
    close() {
      this.$refs.card.clear();
      this.dialog = false;
    },
    async submit() {
      try {
        this.isLoading = true;
        const data = await createToken();
        const card = await accountService.addCard({
          card: {
            customer_id: this.currentBusiness.customer_id,
            source: data.token.id,
          },
        });
        // console.log('card', card)
        if (this.defaultCard) {
          let updateCard = {
            customer_id: this.currentBusiness.customer_id,
            default_source: card.data.id,
          };
          await accountService.changeDefaultCard(updateCard);
        }
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          .fire({
            icon: "success",
            title: "Card was added!",
          });
        this.isLoading = false;
        this.$emit("submit");
        this.close();
      } catch (error) {
        // console.log("error adding card", error);
        this.isLoading = false;
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          .fire({
            icon: "error",
            title: error.data.message,
          });
      }
    },
  },
};
</script>
