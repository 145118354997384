<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn color="primary" dark v-on="on">View Invoice</v-btn>
    </template>
    <v-card tile>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Invoice</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="invoices"
          :loading="loading"
          :sort-by="sortBy"
          :page.sync="page"
          :items-per-page="itemsPerPage"
        >
          <template v-slot:item="{ item }">
            <tr class="pointer">
              <td class="text-left">
                {{ item.created | moment("MM/DD/YYYY") }}
              </td>
              <td class="text-left">{{ item.number }}</td>
              <td class="text-left">{{ item.charge }}</td>
              <td class="text-right">
                {{ (item.amount_due / 100) | currency }}
              </td>
              <td class="text-left text-capitalize">{{ item.status }}</td>
              <td class="justify-end align-center text-right">
                <v-btn text @click="viewInvoice(item)">View</v-btn>
              </td>
            </tr>
          </template>
          <v-alert slot="no-results" :value="true" color="error" icon="warning"
            >Your search for "{{ search }}" found no results.</v-alert
          >
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import accountService from "../service";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "payee-list",
  data() {
    return {
      dialog: false,
      headers: [
        {
          text: "Created",
          align: "left",
          value: "name",
          sortable: true,
        },
        {
          text: "Invoice Number",
          align: "left",
          value: "phoneNumber",
          sortable: true,
        },
        {
          text: "Payment ID",
          value: "email",
          sortable: true,
        },
        {
          text: "Amount",
          value: "email",
          sortable: true,
        },
        {
          text: "Status",
          value: "email",
          sortable: true,
        },
        {
          text: "",
          value: "action",
          sortable: false,
        },
      ],
      invoices: [],
      search: null,
      loading: true,
      page: 1,
      sortBy: "balance",
      pageCount: 0,
      itemsPerPage: 10,
    };
  },
  watch: {
    dialog(val) {
      if (val) {
        this.getList();
      }
    },
  },
  computed: {
    ...mapGetters("global", ["currentBusiness", "checkRightStatus"]),
  },
  methods: {
    ...mapActions("global", ["initUser", "initBusiness"]),
    viewInvoice(invoice) {
      const routeData = invoice.hosted_invoice_url;
      window.open(routeData, "newwindow", "width=850, height=1100");
    },
    getList() {
      this.loading = true;
      return accountService
        .getAllInvoice({ customer_id: this.currentBusiness.customer_id })
        .then((response) => {
          // console.log("response", response);
          this.loading = false;
          this.invoices = response.data;
          return response;
        });
    },
  },
};
</script>
