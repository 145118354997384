<template>
  <div>
    <v-app-bar app clipped-left color="#F0C316">
      <v-app-bar-nav-icon dark @click="$router.push('/')">
        <v-icon>home</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title class="mr-12 align-center">
        <span class="title white--text text-capitalize"
          >Account:
          <span v-if="currentBusiness">{{ currentBusiness.code }}</span>
        </span>
      </v-toolbar-title>
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <!-- <v-row>
        <v-col>
          <div><span>Name: <span class="font-weight-bold">Matt constall service</span></span></div>
          <div><span>Address: <span>Matt constall service</span></span></div>
        </v-col>
      </v-row> -->

        <router-view></router-view>
      </v-container>
    </v-main>
  </div>
</template>
 
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "account",
  data() {
    return {};
  },
  computed: {
    ...mapGetters("global", [
      "currentUser",
      "checkRightStatus",
      "currentBusiness",
    ]),
  },
  async created() {
    await this.initUser();
  },
  methods: {
    ...mapActions("global", ["initUser", "initBusiness"]),
  },
};
</script>
